import React, { useState } from "react"

const defaultState = {
    modalOpen: false,
    externalUrl: null,
    showModal: () => {},
    setExternalUrl: () => {},
    hcpModalOpen: false,
    showHcpModal: () => {},
    modalTrigger: null,
    setModalTrigger: () => {},
    showNav: true
}

const Context = React.createContext(defaultState)

const AppProvider = ({ children }) => {
	const [modalOpen, showModal] = useState(false)
    const [url, setUrl] = useState(null)
    const [hcpModalOpen, showHcpModal] = useState(false)
    const [modalTrigger, setModalTrigger] = useState(null)
    // const showNav = useState(true)
    const store = {
        modalOpen: modalOpen,
        showModal: showModal,
        externalUrl: url,
        setExternalUrl: setUrl,
		hcpModalOpen: hcpModalOpen,
        showHcpModal: showHcpModal,
        modalTrigger: modalTrigger,
        setModalTrigger: setModalTrigger,
        showNav: defaultState.showNav
    }

    return <Context.Provider value={store}>{children}</Context.Provider>
}

export default Context

export { AppProvider }
